<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container">
      <div class="trainer-package-announcement" v-if="!$store.state.is_trainer_package_subscriber">
        <div>
          <i class="fa-solid fa-crown" style="color: #FFD43B;"></i>
          <span>New! {{ plans.trainer_package.name }} offers unlimited horse & equipment "{{ plans.basic.name }}" listings (${{ plans.basic.prices.monthly.price }} individually) for ${{ plans.trainer_package.prices.monthly.price }} per month. Click here for more info:</span>
        </div>
        <router-link to="/trainer-package" class="btn btn-outline-primary">Learn More</router-link>
      </div>
      <div class="trainer-package-announcement" v-else>
        <div>
          <i class="fa-solid fa-crown" style="color: #FFD43B;"></i>
          <span>You are subscribed to the {{ plans.trainer_package.name }} which includes unlimited Basic Listings. When you list your equipment - click on "Basic" when you get to the pricing plan option.</span>
        </div>
        <router-link to="/my-payment-methods" type="button" class="btn btn-outline-primary" style="margin-right: 8px;">Update Payment Method</router-link>
        <button class="btn btn-outline-primary btn-outline-danger" style="" @click="cancelSubscription">Cancel Subscription</button>
      </div>
      <div style="display: flex; gap: 16px; align-items: center; margin-bottom: 24px; flex-wrap: wrap; row-gap: 8px;">
        <h4 style="margin: 0;">Your Equipment</h4>
        <router-link to="/save-equipment" class="btn btn-primary">+ Add new equipment</router-link>
      </div>
      <div style="text-align: center; margin: 20px 0;" v-if="loading"><a-spin /></div>
      <div class="d-flex justify-content-start flex-wrap listing-cards-container" v-if="loading === false" >
        <a-card hoverable class="listing-card" v-for="item in items" :key="item.id">
          <template #cover>
            <div class="active-indicator-badge" v-if="item.is_published && item.trainer_package_subscription_id" style="background-color: #17bc17;">Active under Trainer/Breeder Package</div>
            <div class="active-indicator-badge" v-else-if="item.is_published && item.subscription_id" style="background-color: #17bc17;">Active</div>
            <div class="active-indicator-badge" v-else-if="item.is_draft" style="background-color: #7e7e7e;">Draft</div>
            <div class="active-indicator-badge" v-else style="background-color: #ff982e;">Inactive</div>
            <img src="../assets/img/placeholder_logo.png" v-if="item.images == null || item.images.length === 0" style="object-fit: contain; height: 200px; border: none; padding: 16px;" />
            <img :src="item.images[0]" v-if="item.images && item.images.length > 0" style="object-fit: cover; height: 200px;" />
          </template>
          <template class="ant-card-actions" #actions>
            <a href="javascript:;" @click="openListing(item.id)">Edit Listing</a>
            <router-link v-if="item.is_published && (item.subscription_id != null || item.trainer_package_subscription_id != null)" :to="{path: '/my-payment-methods'}">Update Payment Method</router-link>
            <router-link v-if="item.is_published && (item.subscription_id != null || item.trainer_package_subscription_id != null)" :to="{name: 'change-equipment-plan', params: {id: item.id}}">Change Plan</router-link>
            <router-link v-if="!item.is_published && !item.is_draft" :to="{path: '/plans', query: {next_page: 'save-equipment', next_id: item.id}}">Make Active</router-link>
            <a href="javascript:;" @click="promptDeleteReason(item.id)">Delete Listing</a>
          </template>
          <a-card-meta :title="item.listing_name" :description="((item.trainer_package_subscription_id || item.subscription_id) && item.list_type) ? this.plans[item.list_type].name : null"><template #description></template></a-card-meta>
          <div style="border-top: 1px solid whitesmoke; margin: 24px -24px -24px -24px; padding: 16px 24px;" v-if="item.is_published && (item.subscription_id != null || item.trainer_package_subscription_id != null)">
            <span>Link: </span>
            <a target="_blank" :href="webBaseUrl + '/search-equipment?type=Any&id=' + item.id" style="word-break: break-all;">{{webBaseUrl}}/search-equipment?type=Any&id={{ item.id }}</a>
            <a-tooltip title="Click to copy link">
              <button style="background: none; outline: none; appearance: none; border: none;" @click="copyListingLink(item.id)">
                <i class="fa-solid fa-copy" style="color: lightgray;"></i>
              </button>
            </a-tooltip>
          </div>
        </a-card>
      </div>
      <div v-if="items.length === 0 && loading === false">
        You do not have any listings.
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import axios from 'axios';
import { EditOutlined, SettingOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import ACard from 'ant-design-vue/lib/card';
import ASpin from 'ant-design-vue/lib/spin';
import APopConfirm from 'ant-design-vue/lib/popconfirm';
import APopover from 'ant-design-vue/lib/popover';
import AModal from 'ant-design-vue/lib/modal';
import AButton from 'ant-design-vue/lib/button';
import ATooltip from 'ant-design-vue/lib/tooltip';
import AMessage from 'ant-design-vue/lib/message';
import 'ant-design-vue/lib/card/style/index.css';
import 'ant-design-vue/lib/spin/style/index.css';
import 'ant-design-vue/lib/popover/style/index.css';
import 'ant-design-vue/lib/button/style/index.css';
import 'ant-design-vue/lib/dropdown/style/index.css';
import 'ant-design-vue/lib/menu/style/index.css';
import 'ant-design-vue/lib/modal/style/index.css';
import 'ant-design-vue/lib/button/style/index.css';
import 'ant-design-vue/lib/tooltip/style/index.css';
import 'ant-design-vue/lib/message/style/index.css';

import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';
import Swal from 'sweetalert2';
import plans from '@/constants/plans';

export default {
  components: {
    BaseLayout,
    SubHeader,
    ACard,
    ASpin,
    APopConfirm,
    APopover,
    AModal,
    AButton,
    'a-card-meta': ACard.Meta,
    EditOutlined,
    SettingOutlined,
    DeleteOutlined,
    ATooltip
  },
  data() {
    return {
      loading: false,
      items: [],
      plans,
      webBaseUrl: process.env.VUE_APP_WEB_URL
    }
  },
  async mounted() {
    document.title = 'My Equipment - Mane Street Market';
    window.scrollTo(0, 0);
    await this.loadItems();
  },
  methods: {
    async loadItems() {
      if (this.$route.query.upgrade_completed) {
        Swal.fire({
          title: 'Completed',
          text: 'Plan is upgraded!'
        });
      }
      this.loading = true;
      try {
        let response = await axios.get('/api/my-equipments');
        this.items = response.data.items.sort((a, b) => {
          if (a.is_published && a.trainer_package_subscription_id) {
            return -1;
          } else if (b.is_published && b.trainer_package_subscription_id) {
            return 1;
          }
          if (a.is_published && a.subscription_id) {
            return -1;
          } else if (b.is_published && b.subscription_id) {
            return 1;
          }
          if (a.is_draft) {
            return -1;
          } else if (b.is_draft) {
            return 1;
          }
          return 1;
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    openListing(id) {
      this.$router.push({path: '/save-equipment', query: {id: id}});
    },
    promptDeleteReason(id) {
      const modal = AModal.confirm({
        title: 'Reason for deleting',
        maskClosable: true,
        class: 'ant-modal-no-btns',
        content: <div>
          <p>Select a reason to complete deletion</p>
          <div style="display: flex; gap: 8px; flex-wrap: wrap;">
            <AButton onClick={() => {
              this.promptConfirmDelete(id, 'Sold on MSM');
              modal.destroy();
            }}>Sold on MSM</AButton>
            <AButton onClick={() => {
              this.promptConfirmDelete(id, 'Sold elsewhere');
              modal.destroy();
            }}>Sold elsewhere</AButton>
            <AButton onClick={() => {
              this.promptConfirmDelete(id, 'Taken off market');
              modal.destroy();
            }}>Taken off market</AButton>
            <AButton onClick={() => {
              this.promptConfirmDelete(id, 'Other');
              modal.destroy();
            }}>Other</AButton>
          </div>
          <div style="display: flex; justify-content: flex-end; margin-top: 24px;">
            <AButton type="primary" onClick={() => modal.destroy()}>Cancel</AButton>
          </div>
        </div>
      });
    },
    promptConfirmDelete(id, reason) {
      const modal = AModal.confirm({
        title: 'Are you sure you want to delete this listing?',
        maskClosable: true,
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          this.deleteListing(id, reason);
          modal.destroy();
        }
      });
    },
    async deleteListing(id, reason) {
      this.loading = true;
      try {
        await axios.delete(`/api/equipments/${id}?reason=${encodeURIComponent(reason)}`);
        await this.loadItems();
        AModal.info({
          title: 'Your listing has been deleted.'
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    cancelSubscription() {
      const btnStyle = {
        width: '100%',
        whiteSpace: 'normal',
        textAlign: 'left',
        height: 'auto',
        padding: '8px 16px'
      };
      const modal = AModal.confirm({
        title: 'You have chosen to delete your MSM Trainer/Breeder Package',
        maskClosable: true,
        class: 'ant-modal-no-btns',
        content: <div>
          <p>Please choose one of the following:</p>
          <div style="display: flex; gap: 8px; flex-wrap: wrap;">
            <AButton style={btnStyle} onClick={() => {
              this.confirmCancelSubscription('delete');
              modal.destroy();
            }}><p style="margin: 0;">Delete all listings under the { this.plans.trainer_package.name } subscription</p></AButton>
            <span>or</span>
            <AButton style={btnStyle} onClick={() => {
              this.confirmCancelSubscription('inactive');
              modal.destroy();
            }}><p style="margin: 0;">Cancel subscription but keep listings "inactive"</p></AButton>
          </div>
          <div style="display: flex; justify-content: flex-end; margin-top: 24px;">
            <AButton type="primary" onClick={() => modal.destroy()}>Cancel</AButton>
          </div>
        </div>
      });
    },
    confirmCancelSubscription(option) {
      if (option === 'delete') {
        const modal = AModal.confirm({
          title: `You have chosen to delete all listings under ${ this.plans.trainer_package.name }`,
          maskClosable: false,
          content: <div>
            <label><input type="checkbox" id="subscription_delete_check" style="margin-right: 4px;" />By checking this box I understand that all of my listings will be deleted permanately and my subscription will be cancelled.</label>
          </div>,
          okText: 'Confirm & Cancel Subscription',
          okType: 'danger',
          onOk: (e) => {
            if (!document.querySelector('#subscription_delete_check').checked) {
              return;
            }
            this.proceedCancelSubscription('delete');
            modal.destroy();
          }
        });
      } else if (option === 'subscribe') {
        const modal = AModal.confirm({
          title: `You have chosen to individually subscribe for all currently active listings`,
          maskClosable: false,
          content: `All ${ this.plans.basic.name } horse & equipment listings created free under the ${ this.plans.trainer_package.name } will now be charged individually, at $${ this.plans.basic.prices.monthly.price } per month each. If there are any listings you do not wish to be charged for, please delete them before continuing.`,
          okText: 'Confirm & Cancel Subscription',
          onOk: () => {
            this.proceedCancelSubscription('subscribe');
            modal.destroy();
          }
        });
      } else if (option === 'inactive') {
        const modal = AModal.confirm({
          title: `You have chosen to keep listings "inactive"`,
          maskClosable: false,
          content: `This choice allows you to pause your subscription and also select individual horses & equipment to list separately or through the Trainer/Breeder Package in the future.`,
          okText: 'Confirm & Cancel Subscription',
          onOk: () => {
            this.proceedCancelSubscription('inactive');
            modal.destroy();
          }
        });
      }
    },
    async proceedCancelSubscription(option) {
      let action, successText;
      if (option === 'delete') {
        action = 'unsubscribe_all_delete';
        successText = 'All listings created under the package have been deleted';
      } else if (option === 'subscribe') {
        action = 'subscribe_individual';
        successText = 'All listings created under the package have been individually subscribed';
      } else if (option === 'inactive') {
        action = 'unsubscribe_all_inactive';
        successText = 'All listings created under the package have been made inactive';
      }
      if (action == null) {
        return;
      }
      try {
        const response = await axios.post(`/api/delete-trainer-package-subscription?cascadeAction=${ action }`);
        Swal.fire({
          title: `Your ${ this.plans.trainer_package.name } subscription has been cancelled`,
          text: successText,
        });
        await this.loadItems();
      } catch {
        Swal.fire({
          title: 'There was an error with your cancellation',
          text: 'Please contact us for assistance',
        });
      }
      let userProfile = await db.collection('Users').doc(this.$store.state.user.uid).get();
      this.$store.dispatch('setProfile', userProfile.data());
    },
    copyListingLink(id) {
      navigator.clipboard.writeText(`${ this.webBaseUrl }/search-equipment?type=Any&id=${ id }`);
      AMessage.config({
        top: '90%'
      });
      AMessage.success('Link copied');
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep(.ant-card) {
    display: flex;
    flex-direction: column;
  }

  ::v-deep(.ant-card-actions) {
    flex: 1;
    display: flex;
  }

  ::v-deep(.ant-card-actions li) {
    height: auto;
    align-self: center;
  }

  ::v-deep(.ant-card-actions li span) {
    width: 75%;
    margin: auto;
  }

  ::v-deep(.ant-card-actions > li > span > a:not(.ant-btn)) {
    color: #3a3a3a;
  }

  ::v-deep(.ant-modal-body .ant-modal-confirm-btns) {
    display: none;
  }

  @media (max-width: 480px) {
    ::v-deep(.ant-card-actions) {
      flex-direction: column;
    }

    ::v-deep(.ant-card-actions > li) {
      margin: 8px 0;
      width: 100% !important;
    }

    ::v-deep(.ant-card-actions li span) {
      width: 100%;
      margin: 0;
    }
  }

  .listing-cards-container {
    gap: 10px;
  }

  .listing-card {
    width: calc(33% - 10px * 2 / 3);
  }

  .active-indicator-badge {
    position: absolute;
    width: fit-content;
    padding: 4px 8px;
    color: white;
    border-radius: 4px;
    left: 24px;
    top: 16px;
  }

  .trainer-package-announcement {
    display: flex;
    justify-content: space-between;
    background-color: whitesmoke;
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 32px;
  }

  .trainer-package-announcement > div:first-child {
    display: flex;
    gap: 12px;
    align-items: flex-start;
    width: 80%;
    font-weight: 500;
    line-height: 1.5;
  }

  .trainer-package-announcement > div:first-child svg {
    margin-top: 4px;
  }

  .trainer-package-announcement > a, .trainer-package-announcement > button {
    height: fit-content;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0;
    margin-left: auto;
    white-space: nowrap;

    &:first-of-type {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 480px) {
    .listing-card {
      width: calc(50% - 10px / 2);
    }
    
    .trainer-package-announcement {
      flex-direction: column;
      gap: 16px;
      font-size: 14px;
      margin-top: -24px;
    }

    .trainer-package-announcement > div:first-child {
      width: 100%;
    }

    .trainer-package-announcement > a, .trainer-package-announcement > button {
      font-size: 14px;

      &:first-of-type:not(:last-child) {
        margin-bottom: -8px;
      }
    }
  }
</style>

<style>
  .ant-modal-no-btns .ant-modal-body .ant-modal-confirm-btns {
    display: none;
  }

  .ant-card {
    justify-content: space-between;
  }

  .ant-card .ant-card-body {
    margin-top: 0;
    margin-bottom: auto;
  }

  .ant-card .ant-card-actions {
    flex: 0;
  }

  .ant-message-success {
    display: flex;
    align-items: center;
  }
</style>
